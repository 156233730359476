.login-logo{
    width: 130px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.login-text{
    color: var(--primary-color-5);
    text-align: left;
    font-size: 17px;
    margin-right: 308px;
}

.login-box {
    background-color: var(--primary-color-1);
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 440px;
}
.login-email{
    display: block;
    color: var(--primary-color-5);
    margin-bottom: 5px;
    font-size: 12px;
    margin-left: 22px;
    text-align: left;
    margin-top: 40px;
}


.login-lable {
    display: block;
    color: var(--primary-color-5);
    margin-bottom: 5px;
    font-size: 12px;
    margin-left: 22px;
    text-align: left;
}

.login-input {
    width: 342px;
    padding: 10px;
    margin-top:5px;
    margin-bottom: 15px;
    border: 1px solid var(--primary-color-2);
    border-radius: 3px;
    color: var(--primary-color-5);
    background-color:var(--primary-color-2);
}

.login-button {
    background-color: var(--accent-colors-blue);
    font-size: 12px;
    color: #fff;
    width: 398px;
    padding: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
    border: 1px solid var(--accent-colors-blue);
    border-radius: 3px;
}
input[type="password"] {
    padding-left: 45px;
}

input[type="text"] {
    padding-left: 45px;
}

button:hover {
    cursor: pointer;
}

body {
    background-color: var(--primary-color-4);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.icon-div{
    position: relative !important;
    float: right !important;
    height: 0px !important;
    width: 0px !important;
}

.input-icon{
    position: absolute;
    height: 22px;
    max-height: 22px;
    width: 22px;
    max-width: 22px;
    top: 12.75px;
    right: 385px;
    fill: var(--primary-color-5);
}

.authentication-error-message {
    background-color: rgba(255, 51, 51, 40%);
    border: 2px solid #b82727;
    width: 23rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    padding-inline: 1rem;
    border-radius: 0.2rem;
    color: var(--primary-color-5);
}

.authentication-error-icon {
    height: 1.2rem;
    width: 1.2rem;
    fill: var(--primary-color-5);
}

.error-message-wrapper {
    display: flex;
    justify-content: center;
}

.login-input.error {
    outline: 2px solid red;
}